/**
* Address selection styles
*/
@screen lg {
  .address-selection {
    height: calc(100vh - 140px);
  }

  .borough-logos {
    max-width: 60%;
  }
}
