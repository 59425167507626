/**
 * Carousel styles
 */

.c-carousel {
    width: 100%;
    max-width: 1080px;
    max-height: 730px;
}

.c-carousel-slider {
    max-width: 85%;
    max-height: 550px;
    padding-top: 40px;
    margin: 0 auto;
    margin-bottom: 100px;
}

.c-carousel-slide {
    transition: opacity 1s;
}

.c-carousel-image {
    border-radius: 5px;
    margin: 0 auto;
}

.c-carousel-btn {
    position: absolute;
    top: 20px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 2px solid;

    display: flex;
    justify-content: center;
    align-items: center;

    @apply border-secondary;
}

.c-carousel-btn:hover {
    @apply bg-secondary;
}

.c-carousel-btn:disabled {
    @apply border-gray-400 bg-gray-400;
}

.c-carousel-btn:disabled svg path {
    fill: #fff;
}

.c-carousel-back-button {
    left: 20px;
    padding-right: 2px;
}

.c-carousel-next-button {
    left: 65px;
}

.c-carousel-navigation {
    position: absolute;
    width: 785px;
    max-width: 100%;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0 20px;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.c-carousel-thumbs {
    display: flex;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
}

.c-carousel-thumb {
    width: 145px;
    max-height: 80px;
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 7px;
    border: 2px solid #fff;
    opacity: 0.6;
    overflow: hidden;
}

.c-carousel-thumb:last-child {
    margin-right: 0px;
}

.c-carousel-thumb img {
    border-radius: 5px;
}

.c-carousel-thumb.carousel__dot--selected {
    opacity: 1;
    @apply border-primary;
}

.c-carousel-info {
    text-align: center;
}

.c-carousel-info-number {
    font-weight: 800;
}

.c-carousel-current-slide-index {
    margin-right: 4px;
}

.c-carousel-total-slides {
    margin-left: 4px;
}

.carousel-close {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
}

@screen lg {
    .c-carousel-btn {
        top: 40%;
    }

    .c-carousel-back-button {
        left: 35px;
    }

    .c-carousel-next-button {
        right: 35px;
        left: auto;
    }

    .c-carousel-navigation {
        max-width: 785px;
        height: 150px;
        padding: 0;
        overflow: hidden;
        flex-direction: inherit;
    }

    .c-carousel-thumbs {
        position: absolute;
        right: 0;
        width: 590px;
        max-width: 590px;
    }

    .c-carousel-info {
        text-align: left;
    }
}
