/* Button component */
.btn {
  @apply inline-block text-center border-primary border-2 text-gray-900 font-semibold text-sm rounded-full py-2 px-6;
}

.small-round-button {
  display:block;
  min-height: 38px;
  min-width: 38px;
  border-radius: 50%;
  position: relative;
  border: 2px solid rgb(121, 59, 218);
}

.btn-filled {
  @apply bg-primary text-white;
}

.btn-secondary {
  @apply btn border-secondary;
}

.btn-secondary.btn-filled {
  @apply bg-secondary text-white;
}

.btn-danger {
  @apply btn border-danger;
}

.btn-danger.btn-filled {
  @apply bg-danger text-white;
}

@variants hover {
  .btn-hover:not(:disabled) {
    @apply bg-primary text-white;
  }

  .btn-primary-hover:not(:disabled) {
    @apply bg-primary text-white;
  }

  .btn-secondary-hover:not(:disabled) {
    @apply bg-secondary text-white;
  }

  .btn-danger-hover:not(:disabled) {
    @apply bg-danger text-white;
  }

  .btn-filled:hover {
    @apply bg-transparent text-gray-900 !important;
  }
}

.hover\:btn-hover.btn-filled:hover {
  @apply bg-transparent text-gray-900;
}
.hover\:btn-secondary-hover.btn-filled:hover {
  @apply bg-transparent text-gray-900;
}

.btn-medium {
  @apply text-sm;
}

.btn-small {
  @apply text-sm py-1 px-5;
}

.btn-tiny {
  @apply text-xs py-1 px-3;
}

/* Input component */
.input {
  @apply border border-primary py-2 px-6 rounded;
}

/* Field errors */
.field-error {
  @apply text-red-500 text-xs italic px-1 font-semibold leading-none;
}

.field-error-overflow {
  position: relative;
  padding-top: 5px;
  right: auto;
  bottom: auto;
  background: transparent;
}

/* Modal component */
.modal-color {
  @apply fixed top-0 left-0 w-full h-full bg-gray-200 bg-no-repeat bg-cover bg-center;
}

.modal-bg {
  @apply modal-color;
  background-image: url("../images/modalBackground.png");
}

.modal-window {
  @apply fixed w-full h-full flex justify-center items-center;
}

/* Toasts */
.Toastify__toast-container {
  width: auto !important;
}
.Toastify .Toastify__toast--success. {
}

.Toastify__toast.toasts-wrap {
  padding: 2px;
  min-height: 20px;
  background: rgba(1, 1, 1, 0.5);
  border-radius: 9999px !important;
  @apply rounded-full text-white text-sm;
}

.toasts-heading {
  color: rgba(1, 1, 1, 0.3);
}

/* Breadcrumbs */
.breadcrumb {
  @apply inline-block mr-3;
}

.title-breadcrumbs {
  transition: all 0.5s;
}

/* Tables */
.mobile-table-result:first-child {
  @apply pt-0 !important;
}

.mobile-table-result:last-child {
  @apply border-0 pb-0 !important;
}

.tbl-button:last-child {
  @apply mr-0 !important;
}

table.tbl {
  /* flex flex-row flex-no-wrap */
  @apply w-full overflow-hidden;
  display: inline-table !important;
}

th.tbl-header {
  /* apply border-none; */
  border-bottom: 2px solid #e0e0e0;
  min-height: 40px;
}

tbody.tbl-body {
  /*apply flex-1;*/
  @apply flex-none;
}

tr.tbl-row {
  /* apply flex flex-col flex-no-wrap; */
  @apply table-row;
  border-bottom: 1px solid #dcdcdc;
}

.tbl-row td {
  min-height: 40px;
}

tr.tbl-row:last-child {
  @apply border-none;
}

.tbl-link::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@screen sm {
  tr.tbl-row {
    @apply table-row;
  }

  tbody.tbl-body {
    @apply flex-none;
  }

  table.tbl {
    display: inline-table !important;
  }

  th.tbl-header {
    border-bottom: 2px solid #e0e0e0;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

@screen md {
  .tbl .truncate {
    max-width: 1px;
  }
}

/* Backgrounds */
.bg-pattern {
  background-image: url("../images/modalBackground.png");
  @apply bg-no-repeat bg-cover bg-center;
}

.mark-white {
  color: inherit;
  background: rgba(255, 255, 255, 0.75);
}

.text-color-inherit {
  color: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.render-loading {
}

.render-loading-image {
}

.render-loading-text {
  margin-left: -8px;
}

.render-loading-text:after {
  position: absolute;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis 2000ms infinite;
  content: "";
  width: auto;
  margin-left: 2px;
}

@keyframes ellipsis {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
}

@screen lg {
  .center-screen-form {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@screen smMax {
  .Toastify {
    position: relative;
    z-index: 10000000000;
  }

  .Toastify__toast-container--bottom-center {
    left: 10px !important;
    right: 10px !important;
    bottom: 10px !important;
  }

  .Toastify__toast.toasts-wrap {
    border-radius: 10px !important;
    @apply mb-2 !important;
  }

  .toasts-body {
    @apply px-4 py-2;
  }

  .Toastify__toast-container.Toastify__toast-container--bottom-center {
    margin-left: 0 !important;
  }
}

@screen mdMax {
  .toasts-wrap {
    background: rgba(1, 1, 1, 0.9) !important;
  }

  .Toastify__toast-container--bottom-center {
    margin-left: -212px !important;
  }
}

.ph-item {
  position: relative;
  overflow: hidden;
}

.ph-item::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: phAnimation 0.8s linear infinite;
  animation: phAnimation 0.8s linear infinite;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 46%,
      rgba(255, 255, 255, 0.35) 50%,
      rgba(255, 255, 255, 0) 54%
    )
    50% 50%;
}

@-webkit-keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
