.custom-checkbox-label {
  text-indent: -10000%;
}

.custom-checkbox-container {
  min-height: 180px;
}

@screen md {
  .custom-checkbox-container {
    min-height: 250px;
  }
}

@screen xl {
  .custom-checkbox-container {
    min-height: 350px;
  }
}

.visually-hidden {
  position: absolute;
  left: -100vw;
  /* Note, you may want to position the checkbox over top the label and set the opacity to zero instead. It can be better for accessibilty on some touch devices for discoverability. */
}
