.form-select {
  @apply py-0 px-0;
}

.form-select-dropdown--is-disabled {
  @apply border-gray-500;
}

.form-select-dropdown__control--is-disabled {
  @apply bg-gray-200 !important;
}

.form-select-dropdown--is-disabled::placeholder {
  @apply text-gray-500;
}

.form-select-dropdown--is-disabled + .form-input-label:before,
.form-select-dropdown--is-disabled ~ .form-input-validate:before {
  @apply bg-gray-200 !important;
}

.form-select-dropdown__option,
.form-select-dropdown__single-value {
  @apply flex flex-row justify-start items-center rounded mb-1 cursor-pointer !important;
}

.form-select-dropdown__control {
  @apply border-none border-0 shadow-none !important;
}

.bg-gray-200 > .form-select-dropdown__control {
  @apply bg-gray-200 !important;
}

.form-select-dropdown__clear-indicator,
.form-select-dropdown__dropdown-indicator {
  @apply cursor-pointer;
}

.form-select-dropdown__indicator {
  @apply text-gray-500 !important;
}

.form-select-dropdown__value-container {
  @apply px-3 !important;
}

.form-select-dropdown__indicator-separator {
  @apply bg-transparent !important;
}

.form-select-dropdown__option.form-select-dropdown__option--is-focused {
  @apply bg-gray-200 !important;
}

.form-select-dropdown__option.form-select-dropdown__option--is-selected {
  @apply bg-blue-100 text-black !important;
}

.form-select-dropdown__menu {
  @apply shadow-filter rounded p-2 pb-1 !important;
}

.form-select-dropdown__menu-list {
  @apply m-0 p-0 !important;
}

.form-select-dropdown__placeholder {
  @apply text-gray-500 !important;
}

.form-select:not(.form-select-simple) .form-select-dropdown__control {
  min-height: 45px !important;
}

.form-select-simple {
  @apply text-xs;
}

.form-select-simple .form-select-dropdown__dropdown-indicator {
  padding-left: 0px !important;
}

.form-select-simple .form-select-dropdown__value-container {
  @apply px-0 !important;
  flex: 0 0 auto !important;
  width: 44px !important;
}

.form-select-simple .form-select-dropdown__option {
  @apply justify-center !important;
  padding: 4px !important;
}

.form-select-simple .form-select-dropdown__control {
  @apply bg-transparent text-xs;
  justify-content: flex-start !important;
}

@screen md {
  .form-select.larger-dropdown .form-select-dropdown__menu {
    width: 150% !important;
  }

  .form-select.larger-dropdown.drop-right .form-select-dropdown__menu {
    @apply right-0 !important;
  }

  .form-select.larger-dropdown.drop-left .form-select-dropdown__menu {
    @apply left-0 !important;
  }
}

.registration-plate-select {
  @apply border-black !important;
}

.registration-plate-select .form-select-dropdown__control {
  @apply bg-yellow-plate !important;
  min-height: 55px !important;
}

.registration-plate-select .form-select-dropdown__value-container {
  @apply justify-center items-center;
}

.registration-plate-select ~ .form-input-label {
  @apply hidden;
}

.registration-plate-select .form-select-dropdown__indicators {
  @apply absolute right-0;
  top: 50%;
  transform: translateY(-50%);
}

.registration-plate-select .form-select-dropdown__placeholder,
.registration-plate-select .form-select-dropdown__single-value {
  @apply text-black text-center font-semibold uppercase text-base !important;
}

.registration-plate-select .form-select-dropdown__option {
  @apply bg-yellow-plate text-black text-center font-semibold uppercase text-base justify-center opacity-50 border border-black !important;
}

.registration-plate-select .form-select-dropdown__option:hover,
.registration-plate-select .form-select-dropdown__option--is-selected {
  @apply opacity-100 !important;
}

.registration-plate-select .form-select-dropdown__option--is-selected {
  @apply font-bold;
}

@screen sm {
  .registration-plate-select .form-select-dropdown__indicator svg {
    height: 30px;
    width: 30px;
  }

  .registration-plate-select .form-select-dropdown__placeholder,
  .registration-plate-select .form-select-dropdown__single-value,
  .registration-plate-select .form-select-dropdown__option {
    @apply text-lg;
  }
}

.registration-plate-select .form-select-dropdown__indicator {
  @apply text-black !important;
}

.registration-plate-select .form-select-dropdown__clear-indicator {
  margin-right: -15px !important;
}

.registration-plate-select .form-select-dropdown--is-disabled {
  @apply opacity-50;
}

.registration-plate-select .form-select-dropdown__control--is-disabled {
  @apply opacity-50;
}
