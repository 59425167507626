/* Component classes */
@import "core";

@tailwind base;
@tailwind components;

h1 {
  @apply text-4xl font-bold;
}

body {
  @apply bg-gray-200 overflow-x-hidden overflow-y-auto;
}

@screen mdMax {
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame {
    bottom: 115px !important;
    right: 15px !important;
  }
}

@screen smMax {
  .intercom-lightweight-app-launcher,
  .intercom-launcher-frame {
    bottom: 95px !important;
  }
}

:focus,
a:focus,
button:focus,
a:focus-within,
button:focus-within {
  box-shadow: 0 5px 20px 0 rgba(149, 85, 248, 0.6);
  outline: none !important;
}

body.using-mouse :focus,
body.using-mouse a:focus,
body.using-mouse button:focus,
body.using-mouse a:focus-within,
body.using-mouse button:focus-within {
  box-shadow: none;
}

@tailwind utilities;

/* Reusable utility classes */
.ut-transition {
  @apply transition-all duration-200 ease-in-out;
}
