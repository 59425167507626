.cell-inner-data .cell-image {
    background-position: calc(100% - 10px) 60%;
    background-size: 150px 150px;
}

.cell-image {
    background-position: calc(100% - 40px) calc(100% - 30px);
    background-size: auto calc(100% - 40px);
}

@media screen and (max-width: 768px) {
    .cell-image {
        background-position: calc(100% - 20px) center !important;
        background-size: 100px 110px !important;
    }
}
