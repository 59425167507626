.leaderboard-refresh-icon {
    flex: 0 0 2rem;
}

.leaderboard-refresh-icon.is-loading {
    animation: rotate 1s infinite;
}

@screen sm {
    .leaderboard-wrap {
        max-height: calc(100vh - 60px);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
