/* Textarea component */
.form-textarea-wrap {
	position: relative;
	font-size: 0;
}

.form-textarea-wrap:before,
.form-textarea-wrap:after {
	content: '';
	@apply absolute w-full h-full pointer-events-none;
	transition: transform 0.3s, border-radius 0.4s;
}

.form-textarea-wrap:before,
.form-textarea-wrap:after {
	@apply top-0 left-0 h-full w-full;
	transform-origin: center;
}

.form-textarea-wrap:before {
	@apply border-t border-b border-secondary;
	transform: scale3d(0, 1, 1);
}

.form-textarea-wrap:after {
	@apply border-r border-l border-secondary;
	transform: scale3d(1, 0, 1);
}

.form-textarea-wrap.disabled .form-textarea {
	@apply border-gray-500 text-gray-500;
	@apply text-gray-500;
}

.form-textarea-wrap.disabled .form-textarea,
.form-textarea-wrap.disabled .form-textarea + .form-textarea-label:before,
.form-textarea-wrap.disabled .form-textarea-validate:before {
	@apply bg-gray-200 !important;
}

.form-textarea-wrap.required.valid:before,
.form-textarea-wrap.required.valid:after,
.form-textarea-wrap.required.error:before,
.form-textarea-wrap.required.error:after {
	@apply rounded;
	transform: scale3d(1, 1, 1);
}

.form-textarea-wrap.required.error .form-textarea,
.form-textarea-wrap.required.valid .form-textarea {
	border-color: transparent;
}

.form-textarea-wrap.required.error:before,
.form-textarea-wrap.required.error:after {
	@apply border-red-500;
}

.form-textarea {
	@apply border border-primary-400 py-3 px-4 rounded w-full text-sm outline-none;
	transition: border-color 0s ease 0.3s;
}

.form-textarea-label {
	position: absolute;
	top: 13px;
	left: 11px;
	padding: 0 5px;
	background: inherit;
	transition: 0.2s ease;
	pointer-events: none;
	opacity: 0.5;
	@apply block text-gray-900 text-sm mb-2 rounded;
}

.form-textarea-validate {
	right: 18px;
	bottom: -6px;
	z-index: 0;
	@apply absolute;
}

.form-textarea-label:before,
.form-textarea-validate:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background: white;
	z-index: -1;
}

.form-textarea-validate:before {
	top: 0;
	bottom: auto;
}

.bg-gray-200 ~ .form-textarea-label:before,
.bg-gray-200 ~ .form-textarea-validate:before {
	background: #edf2f7;
}

.ut-transition ~ .form-textarea-label:before,
.ut-transition ~ .form-textarea-validate:before {
	@apply transition-all duration-200 ease-in-out;
}

.form-textarea:focus + .form-textarea-label,
.form-textarea.has-value + .form-textarea-label,
.form-textarea-normal-label {
	transform: translate(9px, -24px) scale(0.95);
	opacity: 1;
	pointer-events: all;
	@apply font-semibold;
}

.form-textarea-tick {
	padding: 0 6px 0 8px;
	height: 13px;
	width: 30px;
	border-radius: 50%;
	pointer-events: none;
	transform: translateY(-2px);
}

.form-textarea-tick-circle {
	animation: fill 0.2s ease-in-out forwards;
}

.form-textarea-tick-path {
	stroke-dashoffset: 100;
	stroke-dasharray: 54;
	animation: stroke 0.3s ease-in-out forwards;
}

@keyframes fill {
	0% {
		fill: transparent;
	}
	100% {
		fill: theme('colors.secondary.default');
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 100;
		stroke-dasharray: 50;
	}
}
