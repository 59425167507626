/**
 * Styles for sidebar component
 */

body.four-o-four-body .sidebar {
  display: none;
}

.sidebar-link {
  width: 40px;
  height: 40px;
}

.sidebar-link:before {
  content: "";
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
  transition: 0.3s ease-in-out;
  @apply absolute rounded-lg opacity-0;
}

.sidebar-item:after {
  content: "";
  border-width: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  transition: 0.3s ease-in-out;
  @apply absolute border-t-4 rounded-t-lg border-tertiary opacity-0;
}

/* Active state */
.sidebar-item.active:after {
  @apply left-0 right-0 opacity-100;
}

/* Active state */
.sidebar-item.active .sidebar-link:before {
  background: rgba(1, 1, 1, 0.2);
  box-shadow: inset 0 1px 1px 0 rgba(1, 1, 1, 0.2);
  transition: top 0.3s ease, right 0.3s ease, bottom 0.3s ease, left 0.3s ease;
  @apply top-0 right-0 bottom-0 left-0 opacity-100;
}

.sidebar-acc-bubble {
  right: 8px;
  top: 8px;
}

@screen lg {
  .sidebar-acc-bubble {
    bottom: 53px;
    left: 11px;
    right: auto;
    top: auto;
  }
}

.sidebar-camera-button {
  right: 15px;
  bottom: 7px;
  width: 24px;
  height: 24px;
}

@screen md {
  .sidebar-link {
    width: 45px;
    height: 45px;
  }
}

@screen lg {
  .sidebar {
    @apply fixed top-0 left-0 flex flex-col w-24 h-screen py-5 items-center;
  }

  .sidebar-item:after {
    content: "";
    border-width: 0;
    top: 50%;
    right: auto;
    left: 0;
    bottom: 50%;
    transition: 0.3s ease-in-out;
    @apply absolute border-l-4 border-t-0 rounded-t-none rounded-r-lg border-tertiary opacity-0;
  }

  /* Active state */
  .sidebar-item.active:after {
    @apply top-0 right-auto bottom-0 opacity-100;
  }

  .sidebar-tooltip {
    @apply absolute right-6 bottom-1/2 transform translate-x-full translate-y-1/2 pointer-events-none rounded px-3 ml-3 whitespace-no-wrap opacity-0;
  }

  .sidebar-tooltip {
    transition: 0.2s;
    background-color: #251342;
  }

  .sidebar-item:hover .sidebar-tooltip {
    transition: 0.3s ease-in-out 0.6s;
    opacity: 1;
  }
}
