/* Input component */
.form-input-wrap {
  position: relative;
}

.form-input-suffix {
  top: 50%;
  transform: translateY(-50%);
}

.form-input-wrap:before,
.form-input-wrap:after {
  content: "";
  @apply absolute w-full h-full pointer-events-none;
  transition: transform 0.3s, border-radius 0.4s;
}

.form-input-wrap:before,
.form-input-wrap:after {
  @apply top-0 left-0 h-full w-full;
  transform-origin: center;
}

.form-input-wrap:before {
  @apply border-t border-b border-secondary;
  transform: scale3d(0, 1, 1);
}

.form-input-wrap:after {
  @apply border-r border-l border-secondary;
  transform: scale3d(1, 0, 1);
}

.form-input-wrap.disabled .form-input {
  @apply border-gray-500 text-gray-500;
  @apply text-gray-500;
}

.form-input-wrap.disabled .form-input,
.form-input-wrap.disabled .form-input + .form-input-label:before,
.form-input-wrap.disabled .form-input-validate:before {
  @apply bg-gray-200 !important;
}

.form-input:-internal-autofill-selected + .form-input-label:before,
.form-input:-internal-autofill-selected ~ .form-input-validate:before {
  background-color: rgb(232, 240, 254) !important;
}

.form-input-wrap.required.valid:before,
.form-input-wrap.required.valid:after,
.form-input-wrap.required.error:before,
.form-input-wrap.required.error:after {
  @apply rounded;
  transform: scale3d(1, 1, 1);
}

.form-input-wrap.required.error .form-input,
.form-input-wrap.required.valid .form-input {
  border-color: transparent;
}

.form-input-wrap.required.error:before,
.form-input-wrap.required.error:after {
  @apply border-red-500;
}

.form-input {
  @apply border border-primary-400 py-3 px-3 rounded w-full text-sm outline-none;
  transition: border-color 0s ease;
  transition-delay: border-color 0.3s;
}
.form-input.prefix {
  @apply pl-6;
}

.form-input-label {
  position: absolute;
  top: 13px;
  left: 11px;
  padding: 0 5px;
  background: inherit;
  transition: 0.2s ease;
  pointer-events: none;
  opacity: 0.5;
  @apply block text-gray-900 text-sm mb-2 rounded;
}

.form-input-validate {
  right: 18px;
  bottom: -7px;
  z-index: 0;
  @apply absolute;
}

.form-input-label:before,
.form-input-validate:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: white;
  z-index: -1;
}

.vehicle-registration-input {
  @apply bg-yellow-plate border-black text-center font-semibold uppercase;
}

.form-input.vehicle-registration-input ~ .form-input-label:before,
.form-input.vehicle-registration-input ~ .form-input-validate:before {
  @apply bg-yellow-plate;
  bottom: 1px;
}

.form-input.vehicle-registration-input ~ .form-input-label:after,
.form-input.vehicle-registration-input ~ .form-input-validate:after {
  content: "";
  position: absolute;
  height: 1px;
  z-index: -1;
  width: 100%;
  left: 0px;
  top: 10px;
  @apply bg-white;
}

.form-input-validate:before {
  top: 0;
  bottom: auto;
}

.bg-gray-200 ~ .form-input-label:before,
.bg-gray-200 ~ .form-input-validate:before {
  background: #edf2f7;
}

.ut-transition ~ .form-input-label:before,
.ut-transition ~ .form-input-validate:before {
  @apply transition-all duration-200 ease-in-out;
}

.form-input:focus + .form-input-label,
.form-input.has-value + .form-input-label,
.form-input-normal-label {
  transform: translate(9px, -24px) scale(0.95);
  opacity: 1;
  pointer-events: all;
  @apply font-semibold;
}

.form-input-password-visibility {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.form-input-password-visibility:focus-within {
  box-shadow: none;
}

.form-input-tick {
  padding: 0 6px 0 8px;
  height: 13px;
  width: 30px;
  border-radius: 50%;
  pointer-events: none;
  transform: translateY(-2px);
}

.form-input-tick-circle {
  animation: fill 0.2s ease-in-out forwards;
}

.form-input-tick-path {
  stroke-dashoffset: 100;
  stroke-dasharray: 54;
  animation: stroke 0.3s ease-in-out forwards;
}

/* Date Picker styles */
.DayPickerInput-Overlay {
  @apply mt-2 pt-4 pb-3 px-2 rounded-md shadow-filter bg-white;
}

.DayPickerInput-Overlay.above {
  transform: translateY(calc(-100% - 70px));
}

.DayPickerInput-Wrapper {
  @apply relative;
}

.DayPicker-NavButton {
  @apply absolute bg-contain bg-no-repeat bg-center;
  top: 32px;
  width: 10px;
  height: 10px;
  background-image: url("../../../../images/chevronPrimary.svg");
}

.DayPicker-NavButton--next {
  right: 28px;
}

.DayPicker-NavButton--prev {
  left: 28px;
  transform: rotate(180deg);
}

.DayPicker {
  @apply text-xs p-2;
}

.DayPicker-Day {
  @apply relative m-1 p-2 h-6 w-6 flex items-center justify-center cursor-pointer;
  z-index: 1;
}

.DayPicker-Day--disabled {
  @apply opacity-50 pointer-events-none;
}

.DayPicker-Day--today:not(.DayPicker-Day--selected) {
  @apply border border-primary rounded-full;
}

.DayPicker-Day--outside {
  opacity: 0;
  visibility: 0;
}

.DayPicker-Day--selected {
  background: #ebebeb;
  color: #939393;
  @apply relative rounded-none;
}

.DayPicker-Day--selected:not(.DayPicker-Day--end):after {
  content: "";
  background: #ebebeb;
  @apply absolute h-full w-2 right-0 transform translate-x-full;
}

.DayPicker-Day--start,
.DayPicker-Day--end {
  @apply text-white;
}

.DayPicker-Day--start:before,
.DayPicker-Day--end:before,
.DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover:before {
  content: "";
  @apply absolute w-full h-full bg-primary rounded-full;
  z-index: -1;
}
.DayPicker-Day:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled):hover {
  @apply text-white;
}

.DayPicker-Day--firstOfMonth {
  @apply rounded-l-full;
}

.DayPicker-Day--lastOfMonth {
  @apply rounded-r-full;
}
.DayPicker-Day--lastOfMonth:after {
  content: none !important;
}

.DayPicker-Day--start {
  @apply rounded-l-full;
}

.DayPicker-Day--end {
  @apply rounded-r-full;
}

.DayPicker-Week .DayPicker-Day:first-child {
  @apply rounded-l-full;
}
.DayPicker-Week .DayPicker-Day:last-child {
  @apply rounded-r-full;
}
.DayPicker-Week .DayPicker-Day:last-child:after {
  content: none;
}

.DayPicker-Weekday {
  @apply border-none text-gray-600 mb-2;
}
.DayPicker-Weekday abbr[title] {
  text-decoration: none;
}

.DayPicker-Caption div {
  font-size: 13px;
  @apply text-center font-bold mb-4;
}

.DayPicker-Months,
.DayPicker-WeekdaysRow,
.DayPicker-Week {
  @apply flex flex-no-wrap;
}

.DayPicker-Month {
  @apply px-1;
}

.DayPicker-WeekdaysRow {
  @apply justify-around;
}

.DayPicker-Week {
  @apply justify-between;
}

/* .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  } */

@keyframes fill {
  0% {
    fill: transparent;
  }
  100% {
    fill: theme("colors.secondary.default");
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 100;
    stroke-dasharray: 50;
  }
}
