/**
 * Styles for 404 component
 */

.four-o-four {
    background-image: url('../../../images/fourofour-bg.svg');
    @apply fixed w-full h-full top-0 left-0 bg-no-repeat bg-cover;
}

.four-o-four-heading {
    font-size: 6rem;
}
