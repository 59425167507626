.loading {
    @apply opacity-0 invisible transition-all duration-1000 z-30;
}

.loading-show {
    @apply opacity-100 visible transition-all duration-500;
}

.loading-bee-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: orbit 7s ease infinite;
}

.loading-bee {
    top: -22px;
    left: 100px;
    transform: rotate(20deg);
}

.loading-bee-small {
    left: 48px;
    top: 44.5px;
    transform: rotate(0deg) translateX(55px) rotate(0deg);
    animation: orbit-small 4s linear infinite;
}

.loading-circle {
    animation: spin 15s linear infinite;
}

.loading-numbers {
    left: 50%;
    top: 50%;
    transform: translateX(-42%) translateY(-50%);
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes orbit {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes orbit-small {
    0% {
        transform: rotate(0deg) translateX(57.5px) rotate(110deg);
    }
    25% {
        transform: rotate(90deg) translateX(57.5px) rotate(110deg);
    }
    50% {
        transform: rotate(180deg) translateX(57.5px) rotate(110deg);
    }
    75% {
        transform: rotate(270deg) translateX(57.5px) rotate(110deg);
    }
    100% {
        transform: rotate(360deg) translateX(57.5px) rotate(110deg);
    }
}
