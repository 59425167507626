.progress-bar-tick-border {
    animation: reveal 0.4s forwards;
}

@keyframes reveal {
    0% {
        transform: rotate(100deg);
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: rotate(0deg);
    }
}
