/**
 * Styles for submenu
 */

.c-submenu {
  height: calc(100vh - 82px);
}

.c-submenu-link {
  transition: all 0.05s ease-in;
}

.c-submenu-active-item .c-submenu-link {
  @apply font-bold opacity-100;
}

.c-submenu-close {
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
}

.c-submenu-close svg path {
  transition: fill 0.2s ease-in-out;
}

.c-submenu-close:hover svg path {
  fill: #000000;
}

@screen md {
  .c-submenu {
    height: calc(100vh - 95px);
  }
}

@screen lg {
  .c-submenu {
    height: 100vh;
    left: 96px !important;
    width: 285px !important;
  }

  .c-submenu-link:hover {
    @apply bg-primary-700;
  }

  .c-submenu-active-item .c-submenu-link {
    @apply bg-primary-700;
  }

  .c-submenu-close {
    right: -20px;
    top: 56px;
    width: 40px;
    height: 40px;
  }
}
