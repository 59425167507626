/* DO NOT REMOVE THIS FILE, THIS CSS FILE REQUIRED FOR OVERRIDING REACT-DATES default styling */
.dateRangeStatistics .DateRangePickerInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  border-color: #bdc0c5;
}

.dateRangeStatistics .DateRangePickerInput_arrow {
  position: relative;
  left: -15px !important;
}

.dateRangeStatistics .DayPicker {
  width: 680px !important;
}

@media only screen and (max-width: 1300px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 90%;
  }
}

@media only screen and (max-width: 1200px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 80%;
  }
}

@media only screen and (max-width: 926px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 75%;
  }
}

@media only screen and (max-width: 780px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 70%;
  }
}

@media only screen and (max-width: 726px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 65%;
  }
}

@media only screen and (max-width: 670px) {
  .dateRangeStatistics .DateRangePickerInput {
    width: 60%;
  }
}

.dateRangeStatistics .DateInput_input {
  padding: 5px;
  font-size: 16px;
}

.dateRangeStatistics .DateInput_input__focused {
  border-bottom: 2px solid #8645ea;
}

.dateRangeStatistics .CalendarDay__selected {
  background: #8645ea;
  color: white;
}

.dateRangeStatistics .CalendarDay__selected:hover {
  background: #8645ea;
  color: white;
}

.dateRangeStatistics .CalendarDay__selected_span {
  background: #d2b9f7;
  color: white;
}

.dateRangeStatistics .CalendarDay__selected_span:hover {
  background: #8645ea;
  color: white;
}

.dateRangeStatistics .CalendarDay__hovered_span:hover,
.dateRangeStatistics .CalendarDay__hovered_span {
  background: #d2b9f7;
}
