/* Button Loading */
.button-loading {
    transition: 0.4s ease;
    width: 0%;
    box-shadow: 0 5px 10px 0 rgba(65, 186, 133, 0.2);
    @apply absolute top-0 left-0 bottom-0 bg-primary overflow-hidden;
}

.btn:disabled,
.btn-secondary:disabled,
.btn-danger:disabled,
.btn-image:disabled {
    @apply opacity-50 cursor-not-allowed;
}

.btn-secondary .button-loading,
.bg-secondary .button-loading,
.border-secondary .button-loading {
    @apply bg-secondary;
}

.button-clip {
    left: -2px;
    transition: 0.4s ease;
    @apply inline-flex h-full absolute w-12 justify-center items-center text-white whitespace-no-wrap;
}

/* Tick SVG */
.button-tick-svg {
    position: absolute;
    top: 50%;
    right: 50%;
    width: 24px;
    height: 17px;
    transition: 0.2s;
    transform: translate(50%, 150%) rotate3d(1, 0, 0, -90deg);
    color: rgba(0, 0, 0, 0.2);
}

.button-tick-svg path {
    stroke: rgba(0, 0, 0, 0.3);
}

/* Is Loading */
.is-loading .button-loading {
    transition: width 6s ease-in;
    width: 90%;
}

.btn.is-loading:hover,
.button-loading.is-loading:hover {
    background-color: transparent !important;
    color: inherit !important;
}

/* Loaded */
.loaded .button-loading {
    transition: width 0.3s ease-in;
    width: 100%;
}

.loaded .button-clip {
    transition: transform 0.4s ease-in-out 0.2s;
    transform: translateY(-120%) rotate3d(1, 0, 0, 90deg);
}

.loaded .button-tick-svg {
    transition: transform 0.4s ease-in-out 0.2s;
    transform: translate(50%, -50%);
}
