/**
 *  Styles for success/confirm Modal component
 */

.modal {
    max-width: 100%;
    background-size: 83px 135px;
    background-position: center 40px;
}

.modal-image-padding {
    padding-top: 180px;
    padding-bottom: 2rem;
}

.modal-min-w {
    min-width: 300px;
}

.modal-max-w {
    max-width: 300px;
}

@screen sm {
    .modal-max-w {
        max-width: 500px;
    }
}
