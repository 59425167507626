.field-checkbox.checked ~ .field-checkbox-label-wrap .field-checkbox-slide {
  border-color: theme("colors.primary.default");
}

.field-checkbox.checked
  ~ .field-checkbox-label-wrap
  .field-checkbox-slide.secondary {
  border-color: theme("colors.secondary.default");
}

.field-checkbox.checked
  ~ .field-checkbox-label-wrap
  .field-checkbox-slide:before {
  transform: translateX(16px);
  background-color: theme("colors.primary.default");
}

.field-checkbox.checked
  ~ .field-checkbox-label-wrap
  .field-checkbox-slide.secondary:before {
  background-color: theme("colors.secondary.default");
}

.field-checkbox-slide:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: theme("colors.gray.inactive");
  transition: 0.2s ease;
}

.field-checkbox-slide {
  flex: 0 0 2.5rem;
}

/* Disabled */
.field-checkbox-wrap.disabled .field-checkbox-slide {
  @apply bg-gray-200 border-gray-500 !important;
}

.field-checkbox-wrap.disabled .field-checkbox-slide:before {
  background-color: theme("colors.gray.500") !important;
}

.field-checkbox-wrap.disabled .field-checkbox-label-wrap {
  @apply cursor-default !important;
}
