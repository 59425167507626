.login-background {
  background-image: url("../images/auth-background-block.svg");
  background-position: left;
  background-size: cover;
  max-width: 65%;
  transform: rotate(-7deg);
  top: 0;
  right: 0;
  backface-visibility: hidden;
}

@media (min-width: 1024px) {
  .login-background {
    background-position: left;
    background-size: cover;
    max-width: 45%;
    transform: rotate(0deg);
  }
}

.auth-shapes__wave {
  height: 75%;
  width: 108%;
  left: 0;
  bottom: 0;
}

.auth-shapes__bee {
  width: 36px;
  position: absolute;
  left: 736.5px;
  bottom: 106px;
  transform: rotate(19deg) scale(1.1);
}

.auth-shapes__line--top {
  right: 30px;
}

@media (min-width: 1280px) {
  .auth-shapes__line--top {
    right: 130px;
  }
}

.auth-slogan {
  padding-right: 50px;
  font-weight: 300;
  font-style: normal;
}

.auth-panel {
  padding-left: 0px;
}

@media (min-width: 1024px) {
  .auth-panel {
    padding-left: 50px;
  }
}

@media (min-width: 1280px) {
  .auth-panel {
    padding-left: 100px;
  }
}

.auth-panel__content {
  width: 350px;
}

.auth-panel__transition {
  min-height: 350px;
  transition: height 0.3s 0.1s;
}

.auth-shapes__circle {
  width: 700px;
}

.auth-shapes__line {
  width: 868px;
}
